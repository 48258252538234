<template>
  <div class="has-nav-bar view-user-address-books-item">
    <!-- :title="data.name" -->
    <van-nav-bar
      title="应急通讯"
      :border="false"
      fixed
      left-arrow
      @click-left="onClickLeft"
    />
    <p style="text-align: center; margin: 0 0 10px;">
      {{ deptTreeCurrentName || data.name }}({{ list.length }}人)
    </p>
    <van-search
      v-model="keywords"
      class="search-bar"
      placeholder="请输入人员名称关键词"
      @search="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <div class="container">
      <div>
        <van-cell
          v-for="item in cardData"
          :key="item.id"
          :title="item.name"
          is-link
          @click="deptClick(item)"
        />
      </div>
      <template v-if="query">
        <p class="contact-person-title">联系人</p>
        <div class="user-address-books-item-list">
          <div v-for="item in list" :key="item.id" class="person-item">
            <div class="head-portrait-box">
              <img :src="avatarSrcSwitch(item.avatar)" />
            </div>
            <div class="person-info-box">
              <div class="name-mobile">
                <span>{{ item.userName }}</span>
                <span>
                  <a :href="'tel:' + item.phone">{{ item.phone }}</a>
                </span>
              </div>
              <p class="position">{{ item.positionName }}</p>
            </div>
          </div>
        </div>
        <p
          v-if="!list.length"
          style="text-align: center; margin: 40px 0; font-size: 14px;"
        >
          暂无数据
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { NavBar, Cell, Tag, Tabs, Tab, List, Search } from "vant";
import { mapActions, mapState } from "vuex";
import defaultAvatar from "../../../assets/images/contractor-person.png";
import { fileUrl } from "@/utils/constant";
import { getTreeDataAllId } from "@/utils";
import { getAddressBooks } from "@/api/urgent.js";

export default {
  name: "UserAddressBookItem",
  components: {
    [NavBar.name]: NavBar,
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [List.name]: List
  },
  props: {},
  data() {
    return {
      orgCode: this.$route.params.sys.id,
      query: this.$route.query.levelIds || "",
      keywords: "",
      data: {},
      list: [],
      infos: {},
      cardData: [],
      allCardData: [],
      allList: [],
      deptTreeData: [],
      deptTreeCurrentData: [],
      deptTreeCurrentName: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  mounted() {
    this.getInfos();
  },
  beforeDestroy() {
    this.data = {};
  },
  methods: {
    ...mapActions(["getAddressBooks"]),
    getInfos() {
      if (!this.query) {
        this.infos = { ...this.$route.params.sys };
        this.tempList = { ...this.infos };
        this.cardData = [...this.infos.children];
        this.allCardData = [...this.infos.children];
        this.deptTreeCurrentName = this.infos.name;
        this.query = this.infos.id;
        // return;
      }
      const params = {
        orgCode: this.userInfo.orgCode,
        groupId: this.infos.id
      };
      getAddressBooks(params).then(res => {
        this.list = [...res];
        this.allList = [...res];
      });
    },
    onClickLeft() {
      this.keywords = "";
      this.$router.back();
      const ids = this.query.split(",");
      const len = ids.length;
      this.getList([this.tempList], ids, len - 2, 0);
      ids.splice(len - 1, 1);
      this.query = ids.join(",");
      this.infos.id = ids[len - 2];
      this.getInfos();
    },
    getList(data, ids, len, count) {
      data.forEach(item => {
        if (ids[count] === item.id) {
          if (len > count) {
            count++;
            this.getList(item.children, ids, len, count);
          } else {
            this.cardData = item.children;
            this.infos.id = item.id;
            this.deptTreeCurrentName = item.name;
          }
        }
      });
    },
    onSearch() {
      if (this.keywords) {
        this.list = this.list.filter(item => {
          return item.userName.includes(this.keywords);
        });
        this.cardData = this.cardData.filter(item => {
          return item.name.includes(this.keywords);
        });
        return;
      } else {
        this.list = [...this.allList];
        this.cardData = [...this.allCardData];
      }
    },
    deptClick(data) {
      const levelIds = this.$route.query.levelIds
        ? `${this.$route.query.levelIds},${data.id}`
        : data.id;
      this.$router.push({
        path: this.$router.path,
        query: {
          levelIds
        }
      });
      this.cardData = data.children;
      this.deptTreeCurrentName = data.name;
      this.query = `${this.query},${data.id}`;
      this.infos.id = data.id;
      this.getInfos();
    },
    avatarSrcSwitch(fileId) {
      if (fileId) {
        return `${fileUrl}${fileId}`;
      }
      return defaultAvatar;
    }
  }
};
</script>
<style lang="scss">
.view-user-address-books-item {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #f0f0f0;
  &.has-nav-bar {
    // padding-top: 140px;
    padding-top: 70px;
  }
  .search-bar {
    // position: fixed;
    // top: 58px;
    // z-index: 1;
    width: 100%;
    background-color: #f0f0f0;
  }
  .container {
    overflow-y: auto;
    height: calc(100vh - 156px);
    padding: 0 10px;
    .contact-person-title {
      font-size: 14px;
      padding: 10px 0 2px;
    }
    .user-address-books-item-list {
      background-color: #fff;
      .person-item {
        display: flex;
        padding: 10px;
        box-sizing: border-box;
        .head-portrait-box {
          // display: inline-block;
          height: 40px;
          width: 40px;
          img {
            display: block;
            width: 40px;
            height: 40px;
          }
        }
        .person-info-box {
          flex: 1;
          // display: inline-block;
          vertical-align: top;
          padding: 0 0 0 10px;
          font-size: 14px;
          line-height: 18px;
          .name-mobile {
            width: 100%;
            display: flex;
            justify-content: space-between;
            a {
              color: #aeb3c0;
              margin: 0 0 0 10px;
            }
          }
          .position {
            padding: 8px 0 0;
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>
